import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

/* !DEPRECATED */
// const META_DATA = [
//   { slug: '' },
//   { title: 'Numuworld - Case Studies', slug: 'case-studies' },
//   { title: 'Numuworld - FAQ', slug: 'faqs' }
// ];

const META_DATA = {
  index: { slug: '' },
  'case-studies': { title: 'Numuworld - Case Studies', slug: 'case-studies' },
  faqs: { title: 'Numuworld - FAQ', slug: 'faqs' },
  campaigns: { title: 'Numuworld - Campaigns', slug: 'faqs' },
  pricing: { title: 'Numuworld - Pricing', slug: 'faqs' },
  terms: { title: 'Numuworld - Terms of service', slug: 'terms' },
  privacy: { title: 'Numuworld - Privacy policy', slug: 'privacy' },
  termUse: { title: 'Numuworld - Terms of use', slug: 'termUse' },
  activationForm: { title: 'Activation Form', slug: 'activationForm' },
  gdpr: { title: 'Numuworld - GDPR', slug: 'gdpr' },
  legal: { title: 'Numuworld - Legal', slug: 'legal' },
  cookies: { title: 'Numuworld - Cookies', slug: 'cookies' },
  emailDisclaimer: { title: 'Numuworld - Email disclaimer', slug: 'emailDisclaimer' },
  career: {
    title: 'Numu Careers',
    slug: 'career',
    image: 'https://numu-content.s3.eu-west-1.amazonaws.com/career_header.png',
    description: "we are making everyone's influence count. We are also hiring"
  }
};
const SEO = ({ page, language }) => {
  /* !DEPRECATED */

  // const [type, setType] = useState(0);
  // useEffect(() => {
  //   switch (page) {
  //     case 'case-studies':
  //       setType(1);
  //       break;
  //     case 'faqs':
  //       setType(2);
  //       break;
  //     case 'career':
  //       setType(3);
  //       break;

  //     default:
  //       setType(0);
  //       break;
  //   }
  // }, [page]);

  const COMMON = {
    title: 'Numuworld',
    image: `https://numu-content.s3.eu-west-1.amazonaws.com/numulogo_full.svg`
  };

  const schemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: process.env.GATSBY_HOME,
    name: META_DATA[page].title || 'Numuworld'
  };

  const description =
    language === 'es'
      ? 'Numu es la plataforma todo-en-uno que conecta a marcas con influencers y agencias top. Aumenta tu reputación, tráfico y ventas a través de contenido de calidad.'
      : 'Numu is the new all-in-one platform that connects great brands with social media influencers and top PR agencies. Boost your reputation, traffic and sales through high quality and affordable content.';
  return (
    <Helmet defer={false}>
      {/* General tags */}
      <title>{META_DATA[page].title || COMMON.title}</title>
      <meta name="description" content={META_DATA[page].description || description} />
      <meta name="image" content={META_DATA[page].image || COMMON.image} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <script type="application/Id+json">{JSON.stringify(schemaOrgJSONLD)}</script>

      {/* OpenGraph tags */}
      <meta
        property="og:url"
        content={`${process.env.GATSBY_HOME}/${language}/${META_DATA[page].slug}`}
      />

      <meta property="og:type" content="website" />
      <meta property="og:locale" content={language === 'es' ? 'es' : 'en_US'} />
      <meta property="og:title" content={META_DATA[page].title || COMMON.title} />

      <meta property="og:description" content={META_DATA[page].description || description} />

      <meta property="og:image" content={META_DATA[page].image || COMMON.image} />

      {/* Twitter card tags */}
      <meta name="twitter:card" content="summary_large_image" />

      <meta
        property="twitter:url"
        content={`${process.env.GATSBY_HOME}/${language}/${META_DATA[page].slug}`}
      />

      <meta name="twitter:creator" content="squawkvoices" />

      <meta
        name="twitter:title"
        content={META_DATA[page].twitterTitle || META_DATA[page].title || COMMON.title}
      />

      <meta name="twitter:description" content={META_DATA[page].description || description} />

      <meta
        name="twitter:image"
        content={META_DATA[page].twitterImage || META_DATA[page].image || COMMON.image}
      />
    </Helmet>
    // DEPRECATED
    /* <Helmet>

<title>{META_DATA[type].title || COMMON.title}</title>
<meta name='description' content={description} />
<meta name='image' content={META_DATA[type].image || COMMON.image} />
<meta
  name='viewport'
  content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
/>
<script type='application/Id+json'>
  {JSON.stringify(schemaOrgJSONLD)}
</script>


<meta
  property='og:url'
  content={`${process.env.GATSBY_HOME}/${language}/${META_DATA[type].slug}`}
/>

<meta property='og:type' content='website' />
<meta property='og:locale' content={language === 'es' ? 'es' : 'en_US'} />
<meta
  property='og:title'
  content={META_DATA[type].title || COMMON.title}
/>

<meta property='og:description' content={description} />

<meta
  property='og:image'
  content={META_DATA[type].image || COMMON.image}
/>


<meta name='twitter:card' content='summary_large_image' />

<meta
  property='twitter:url'
  content={`${process.env.GATSBY_HOME}/${language}/${META_DATA[type].slug}`}
/>

<meta name='twitter:creator' content='squawkvoices' />

<meta
  name='twitter:title'
  content={
    META_DATA[type].twitterTitle || META_DATA[type].title || COMMON.title
  }
/>

<meta name='twitter:description' content={description} />

<meta
  name='twitter:image'
  content={
    META_DATA[type].twitterImage || META_DATA[type].image || COMMON.image
  }
/>
</Helmet> */
  );
};

SEO.propTypes = {
  language: PropTypes.string.isRequired,
  page: PropTypes.string
};

SEO.defaultProps = {
  page: ''
};

export default SEO;
