// import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import * as React from 'react';
import { PageProps } from 'gatsby';
import Layout from '../components/ui/layout';
import DefaultLanguage from '../hoc/default-language';
import SEO from '../components/ui/seo';

const Career = ({ location, pageContext }) => (
  <>
    <SEO page="career" language={pageContext.language} />
    <DefaultLanguage location={location}>
      <Layout location={location} language={pageContext.language} page="job-application">
        <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js" />
        <iframe
          title="Job Application Form"
          className="airtable-embed airtable-dynamic-height"
          src="https://airtable.com/embed/shrBLkPWMjHAj0q7M?backgroundColor=cyan"
          frameBorder={0}
          // onmousewheel=""
          width="100%"
          height="2000"
          style={{ background: 'transparent', border: '1px solid #ccc' }}
          // style="background: transparent; border: 1px solid #ccc;"
        />
      </Layout>
    </DefaultLanguage>
  </>
);

Career.propTypes = {
  pageContext: PropTypes.instanceOf(PageProps).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default Career;
